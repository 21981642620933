<template>
  <BOverlay
    :show="isLoading"
    spinner-variant="primary"
    variant="light"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <BCard>
      <BRow class="justify-between items-center">
        <strong
          v-if="data.submission_type === 'Berlangganan'"
          class="ml-2 text-xl text-black font-medium"
        >
          Pengajuan Berlangganan
        </strong>
        <strong
          v-else
          class="ml-2 text-xl text-black font-medium"
        >
          Pengajuan Tambah Produk
        </strong>
        <div
          v-if="data.status !== 'Ditolak' && data.status !== 'Disetujui'"
          class="flex"
        >
          <BButton
            variant="outline-primary"
            class="mr-1 w-auto"
            type="submit"
            :disabled="size(selected) !== size(data.product) || isEmpty(items)"
            @click="rejectSubmission"
          >
            <BSpinner
              v-if="isLoadingReject"
              small
              variant="light"
            />
            Tolak Pengajuan
          </BButton>
          <BButton
            variant="primary"
            class="mr-1 w-auto"
            type="submit"
            :disabled="validationDisabled() || disabledBtn"
            @click="approveSubmission"
          >
            <BSpinner
              v-if="isLoadingApprove"
              small
              variant="light"
            />
            Setujui Pengajuan
          </BButton>
        </div>
      </BRow>
      <BRow class="mt-1">
        <BCol lg="6">
          <BCard
            style="border: 1px solid #e2e2e2; border-radius: 4px"
            body-class="bodyClass"
          >
            <div>

              <div class="text-black text-lg">
                Mitra Gudang
              </div>
              <div class="flex my-1">
                <BAvatar :src="data.warehouse_logo_url" />
                <div class="ml-1">
                  <strong class="text-black">{{ data.warehouse_name }}</strong>
                  <div>{{ DAY_MONTH_YEAR(data.warehouses_join_date) }}</div>
                </div>
              </div>
              <BRow class="flex items-center mt-1">
                <BCol lg="5">
                  <strong class="text-black mr-1"> Owner </strong>
                </BCol>
                <BCol
                  lg="7"
                  class="flex"
                >
                  <span>{{ data.warehouses_owner }} </span>
                  <img
                    src="https://storage.googleapis.com/komerce/assets/verified-shield.svg"
                    alt="Komerce"
                    class="w-5 ml-[5px]"
                  >
                </BCol>
              </BRow>
              <BRow class="flex items-center mt-1">
                <BCol lg="5">
                  <strong class="text-black mr-1"> Status </strong>
                </BCol>
                <BCol lg="7">
                  <span class="warehouseStatus">{{ data.warehouse_availability }}</span>
                </BCol>
              </BRow>
              <BRow class="flex mt-1">
                <BCol lg="5">
                  <strong class="text-black mr-1"> Alamat Gudang </strong>
                </BCol>
                <BCol
                  v-b-tooltip.hover.top="'Scroll untuk melihat detail alamat'"
                  lg="7"
                  class="overflow-auto h-16 cursor-pointer"
                >
                  <span>{{ data.warehouses_address }}</span>
                </BCol>
              </BRow>
            </div>
            <div class="flex justify-center mt-2">
              <BButton
                variant="outline-primary"
                class="mr-1 w-full"
                @click="handlePhone(data.warehouses_phone)"
              >
                Chat
              </BButton>
              <BButton
                variant="outline-primary"
                class="w-full"
                @click="handleRouteWarehouseDetail(data.mitra_id)"
              >
                Lihat Info Gudang
              </BButton>
            </div>
          </BCard>
        </BCol>
        <BCol lg="6">
          <BCard
            style="border: 1px solid #e2e2e2; border-radius: 4px"
            body-class="bodyClass"
          >
            <div>
              <div class="text-black text-lg">
                Partner
              </div>
              <div class="flex my-1">
                <BAvatar :src="data.partner_logo_url" />
                <div class="ml-1">
                  <strong class="text-black">{{ data.partner_name }}</strong>
                  <div>{{ data.partner_city }}</div>
                </div>
              </div>
              <BRow class="flex items-center mt-1">
                <BCol lg="4">
                  <strong class="text-black mr-1"> Owner </strong>
                </BCol>
                <BCol lg="8">
                  <span>{{ data.partner_owner }}</span>
                </BCol>
              </BRow>
              <BRow class="flex items-center mt-1">
                <BCol lg="4">
                  <strong class="text-black mr-1"> Email </strong>
                </BCol>
                <BCol lg="8">
                  <span>{{ data.partner_email }}</span>
                </BCol>
              </BRow>
              <BRow class="flex items-center mt-1">
                <BCol lg="4">
                  <strong class="text-black mr-1"> Status </strong>
                </BCol>
                <BCol lg="8">
                  <span :class="classWarehouse(data.status)">{{ data.status }}</span>
                </BCol>
              </BRow>
              <BRow class="flex items-center mt-1">
                <BCol lg="4">
                  <strong class="text-black mr-1"> Produk </strong>
                </BCol>
                <BCol lg="8">
                  <span>{{ data.product_total }}</span>
                </BCol>
              </BRow>
            </div>
            <BButton
              variant="outline-primary"
              class="mt-2 w-full"
              @click="handlePhone(data.partner_phone)"
            >
              Chat
            </BButton>
          </BCard>
        </BCol>
      </BRow>
      <h4 class="text-black mb-2">
        Daftar Produk
      </h4>
      <BTable
        ref="selectableTable"
        responsive
        class="my-0"
        :items="items"
        :fields="fields"
        empty-text="Tidak ada data yang ditampilkan."
        :select-mode="selectMode"
        show-empty
        @row-selected="onRowSelected"
      >
        <template #head(addon_cost)="value">
          <div class="flex items-center">
            <span>{{ value.label }}</span>
            <img
              v-b-tooltip.hover.top="'Biaya fullfilement untuk penambahan quantity dari suatu barang dalam 1 resi'"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt="Komerce"
              class="ml-[5px]"
            >
          </div>
        </template>
        <template #cell(checkbox)="value">
          <BCheckbox
            v-if="(data.status !== 'Ditolak' && data.status !== 'Disetujui') && value.item.is_bundling === 1"
            v-model="selected"
            v-b-tooltip.hover.top="isProductIncomplete(value.item) ? 'Produk satuan penyusun bundling harus dipilih terlebih dahulu' : ''"
            :value="value.item"
            :disabled="isProductIncomplete(value.item)"
            @change="select"
          />
          <BCheckbox
            v-if="(data.status !== 'Ditolak' && data.status !== 'Disetujui') && value.item.is_bundling === 0"
            v-model="selected"
            :value="value.item"
            @change="select"
          />
        </template>

        <template #cell(product_name)="value">
          <div
            v-if="value.item.images_path === null"
            class="d-flex align-items-start"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
              alt="dataimg"
              class="mr-1"
              style="width: 40px"
            >
            <div class="d-grid align-items-center">
              <span
                v-if="value.item.is_reject === 1"
                class="reject"
              >
                Ditolak
              </span>
              <div class="font-medium">
                {{ value.item.product_name }}
              </div>
              <div
                class="my-0 text-[12px] text-primary"
              >
                SKU: {{ value.item.sku }} <span class="text-[#333] text-[10px]">({{ value.item.product_weight }} gram)</span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center"
          >
            <img
              :src="value.item.images_path"
              alt="dataimg"
              class="mr-1"
              style="width: 40px"
            >
            <div class="d-grid align-items-center">
              <span
                v-if="value.item.is_reject === 1"
                class="reject"
              >
                Ditolak
              </span>
              <div class="font-medium">
                {{ value.item.product_name }}
              </div>
              <div
                class="my-0 text-[12px] text-primary"
              >
                SKU: {{ value.item.sku }} <span class="text-[#333] text-[10px]">({{ value.item.product_weight }} gram)</span>
              </div>
            </div>
          </div>
        </template>
        <template #cell(variants)="value">
          <div v-if="value.item.product_variants.length">
            <div
              v-for="(item, index) in value.item.product_variants"
              :key="index"
            >
              <span v-b-tooltip.hover.top="item.variant_name.length >= 10 ? item.variant_name : null">{{ truncateWithEllipsis(item.variant_name) }}</span>
            </div>
          </div>
          <div v-else>
            Tidak ada variasi
          </div>
        </template>
        <template #cell(product_height)="value">
          <div style="min-width: 130px !important">
            <div>P : {{ value.item.product_length }} cm</div>
            <div>L : {{ value.item.product_width }} cm</div>
            <div>T : {{ value.item.product_height }} cm</div>
          </div>
        </template>
        <template #cell(packing_material)="value">
          <div v-if="data.status !== 'Ditolak' && data.status !== 'Disetujui'">
            <BDropdown
              :text="handlePackingText(value.item.packing_detail)"
              variant="outline-dark"
              :disabled="disabled(value.item.id)"
            >
              <div
                v-for="(item, index) in value.item.packing_detail"
                :key="index"
                class="p-50"
              >
                <BFormCheckbox
                  v-model="item.isActive"
                  @input="packingOptions(value), item.isActive === true ? value.item.packing_detail[index].layer = 1 : value.item.packing_detail[index].layer = undefined"
                  @change="recalculate(value.item)"
                >
                  <span class="text-black">{{ item.packing_material }}</span>
                </BFormCheckbox>
              </div>
            </BDropdown>
          </div>
          <div v-else>
            {{ labelPacking(value) }}
          </div>
        </template>
        <template #head(packing_layer)="value">
          <div class="flex items-center">
            <span>{{ value.label }}</span>
            <img
              v-b-tooltip.hover.top="'PL : Plastik\nBW : Bubble Wrap\nKD : Kardus\nAP: Amplop'"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt="Komerce"
              class="ml-[5px]"
            >
          </div>
        </template>
        <template #cell(packing_layer)="value">
          <div
            v-if="data.status !== 'Ditolak' && data.status !== 'Disetujui'"
            class="flex gap-2"
          >
            <b-form-group
              v-for="(item, index) in LabelForm"
              :key="index"
              :label="item"
            >
              <b-input
                v-model="value.item.packing_detail[index].layer"
                :formatter="formatPackingLayer"
                number
                :disabled="value.item.packing_detail[index].isActive === false"
                @change="recalculate(value.item)"
              />
            </b-form-group>
          </div>
          <div
            v-else
            style="white-space: pre-line;"
          >
            {{ handleMultiplePacking(value.item.packing_detail) }}
          </div>
        </template>
        <template #cell(fulfillment_cost)="value">
          <BFormInput
            v-if="data.status !== 'Ditolak' && data.status !== 'Disetujui'"
            v-model="value.item.fulfillment_price_recommendation"
            type="number"
            :disabled="disabled(value.item.id)"
            @keypress="isNumber"
          />
          <span v-else>{{ IDR(value.item.fulfillment_cost) }}</span>
        </template>
        <template #cell(addon_cost)="value">
          <div
            v-if="data.status !== 'Ditolak' && data.status !== 'Disetujui'"
            class="flex items-center min-w-[300px]"
          >
            <BFormInput
              v-model="value.item.addon_cost"
              type="number"
              style="width: 100px"
              :disabled="disabled(value.item.id)"
              @keypress="isNumber"
            />
            <span class="mx-1">/</span>
            <BInputGroup>
              <BFormInput
                v-model="value.item.addon_minimum"
                type="number"
                style="width: 50px"
                :disabled="disabled(value.item.id)"
                @keypress="isNumber"
              />
              <template #append>
                <BFormInput
                  placeholder="Pcs"
                  disabled
                  style="width: 60px"
                />
              </template>
            </BInputGroup>
          </div>
          <div
            v-else
            class="min-w-[200px]"
          >
            {{ value.item.addon_cost }} / {{ value.item.addon_minimum }} Pcs
          </div>
        </template>
        <template #row-details="value">
          <b-row
            class="-mt-[1.5rem]"
            :class="data.status !== 'Ditolak' && data.status !== 'Disetujui' ? 'pl-[6.6rem]' : 'pl-[4.8rem]'"
          >
            <b-col cols="12">
              <div class="flex">
                <div
                  v-b-toggle="`bundling-${String(value.index)}`"
                  class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                    alt="bundling"
                    width="16"
                  >
                  <div>Bundling</div>
                </div>
              </div>
              <b-collapse
                :id="`bundling-${String(value.index)}`"
              >
                <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                  <div class="border rounded-t w-[50%]">
                    <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-black font-[500]">
                      Produk
                    </div>
                    <b-table
                      :items="value.item.bundle_component"
                      :fields="fieldsBundling"
                    >
                      <template #cell(no)="value">
                        {{ value.index + 1 }}
                      </template>
                      <template #cell(product_name)="value">
                        <div class="flex gap-[16px] items-start">
                          <img
                            :src="value.item.product_image[0] ? value.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                            alt="product"
                            width="52"
                            height="52"
                          >
                          <div class="space-y-[4px]">
                            <div class="font-medium">
                              {{ value.item.product_name }}
                            </div>
                            <div class="text-primary text-[12px]">
                              SKU: {{ value.item.sku }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="flex ml-[0.8rem]">
                  <div
                    v-b-toggle="`bundling-${String(value.index)}`"
                    class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                  >
                    <feather-icon
                      icon="ChevronUpIcon"
                    />
                    <div>Tutup</div>
                  </div>
                </div>
              </b-collapse>
            </b-col>
          </b-row>
        </template>
      </BTable>
    </BCard>
  </BOverlay>
</template>

<script>
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { axiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { isNumber } from '@/libs/helpers'
import { IDR } from '@/libs/currency'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { listDetail, fieldsBundling } from './config'

export default {
  data() {
    return {
      selectMode: 'multi',
      isLoading: false,
      isLoadingApprove: false,
      isLoadingReject: false,
      data: {},
      DAY_MONTH_YEAR,
      items: [],
      fields: listDetail,
      fieldsBundling,
      selected: [],
      isSelected: false,
      idSubmission: this.$route.params.id,
      isNumber,
      IDR,
      isEmpty,
      size,
      disabledBtn: false,
      idPacking: [],
      pm: [],
      LabelForm: ['PL', 'KD', 'BW', 'AP'],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    /**
     * Recalculate fulfillment cost from the given data.
     *
     * @param {Object} data - The data to be recalculated.
     */
    recalculate(produk) {
      const {
        packing_detail,
        id,
        product_price,
        average_price,
        product_length,
        product_width,
        product_height,
        product_is_variant: isVariant,
      } = produk
      const payload = {
        warehouse_id: this.data.mitra_id,
        packings: packing_detail.reduce((acc, item) => {
          if (item.isActive) {
            acc.push({
              option_id: item.id_packing,
              packing_name: item.packing_material,
              layer: item.layer,
              rate: item.rate,
            })
          }
          return acc
        }, []),
        product_id: id,
        product_price,
        average_price,
        product_length,
        product_width,
        product_height,
        is_variant: isVariant !== '0',
      }
      const newData = produk
      this.$http_new.post('komship/api/v1/admin/fulfillments/recalculate', payload)
        .then(res => {
          newData.fulfillment_price_recommendation = res.data.data.fulfillment_cost
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal melakukan rekalkulasi. Silakan coba lagi nanti.' })
        })
    },
    async getData() {
      this.isLoading = true
      const url = `/v1/warehouse/submission/detail/${this.idSubmission}`
      await axiosIns
        .get(url)
        .then(async res => {
          await axiosIns.get('/kompack/select-option/packing')
            .then(result => {
              const { data } = result.data
              const packingData = data.map(packing => ({
                id_packing: packing.id,
                packing_material: packing.name,
                isActive: false,
                rate: Number(packing.rate),
              }))
              this.pm = packingData
            })
          const { data } = res.data
          this.data = data
          this.items = data.product.map(item => ({
            ...item,
            _showDetails: item.is_bundling === 1,
            packing_detail: this.removeDuplicates(this.pm.concat(item.packing_detail.map(packingItem => ({
              ...packingItem,
              isActive: false,
            })))),
          }))
          this.isLoading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    async approveSubmission() {
      this.isLoadingApprove = true
      this.disabledBtn = true
      const dataProduct = this.selected.map(item => ({
        detail_id: item.id,
        packing_material: item.packing_detail.filter(active => active.isActive === true).map(id => id.id_packing),
        packing_layer: item.packing_detail.filter(active => active.isActive === true).map(layer => layer.layer),
        fulfillment_cost: Number(item.fulfillment_price_recommendation),
        addon_cost: Number(item.addon_cost),
        addon_minimum: Number(item.addon_minimum),
      }))

      const payload = {
        submission_id: this.data.id,
        product: dataProduct,
      }
      const url = '/v1/warehouse/submission/approved'
      await axiosIns.put(url, payload)
        .then(() => {
          this.isLoadingApprove = false
          this.disabledBtn = true
          this.getData()
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Berhasil setujui pengajuan berlangganan',
              variant: 'success',
            },
          }, 2000)
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoadingApprove = false
          this.disabledBtn = true
        })
    },
    async rejectSubmission() {
      this.isLoadingReject = true
      this.disabledBtn = true
      const url = `/v1/warehouse/submission/reject/${this.idSubmission}`
      await axiosIns.put(url)
        .then(() => {
          this.isLoadingReject = false
          this.disabledBtn = true
          this.getData()
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Berhasil menolak pengajuan berlangganan',
              variant: 'success',
            },
          }, 2000)
          this.$router.push({ path: '/history-submission' })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoadingReject = false
          this.disabledBtn = true
        })
    },
    handlePhone(value) {
      if (value.startsWith('0')) {
        return window.open(`https://wa.me/62${value.substring(1)}`, '_blank')
      }
      return window.open(`https://wa.me/${value}`, '_blank')
    },
    classWarehouse(status) {
      if (status === 'Sedang Diajukan') {
        return 'isBeingSubmitted'
      }
      if (status === 'Disetujui') {
        return 'approve'
      }
      if (status === 'Ditolak') {
        return 'reject'
      }
      return ''
    },
    handleRouteWarehouseDetail(idWarehouse) {
      this.$router.push({
        path: `/kompack-rincian-mitra-gudang/${idWarehouse}/information`,
      })
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      if (this.isSelected) {
        this.$refs.selectableTable.selectAllRows()
      }
      if (!this.isSelected) {
        this.$refs.selectableTable.clearSelected()
      }
    },
    select(value) {
      this.selected = value
      this.isSelected = false
    },
    labelPacking(packing) {
      const { item } = packing
      const filtered = item.packing_detail.filter(active => active.isActive)
      return filtered.map(e => e.packing_material).join(', ')
    },
    disabled(id) {
      for (let i = 0; i < this.selected.length; i += 1) {
        if (this.selected[i].id === id) return false
      }
      return true
    },
    validationDisabled() {
      for (let i = 0; i < this.selected.length; i += 1) {
        if (this.selected[i].packing_detail.length === 0) return true
      }
      if (this.selected.length === 0) return true
      if (this.items.length === 0) return true
      return false
    },
    removeDuplicates(packing) {
      const result = []

      for (let i = 0; i < packing.length; i += 1) {
        const item = packing[i]
        let isExist = false

        for (let j = 0; j < result.length; j += 1) {
          const existItem = result[j]

          if (existItem.id_packing === item.id_packing) {
            isExist = true
            existItem.isActive = true
            if (item.layer !== undefined) {
              existItem.layer = item.layer
            }
            break
          }
        }

        if (!isExist) {
          const newItem = {
            id_packing: item.id_packing,
            packing_material: item.packing_material,
            isActive: item.isActive,
            layer: item.layer !== undefined ? item.layer : null,
            rate: item.rate,
          }
          result.push(newItem)
        }
      }
      return result
    },
    packingOptions(value) {
      const { item } = value
      return item.packing_detail.filter(e => e.isActive === true)
    },
    handlePackingText(data) {
      const selectedOption = data.filter(option => option.isActive === true)

      if (size(selectedOption) === 1) return selectedOption[0].packing_material
      if (size(selectedOption) > 1) return `${selectedOption.length} Terpilih`
      return 'Pilih bahan'
    },
    formatPackingLayer(value) {
      const numericValue = value.replace(/[^0-9]/g, '')

      if (numericValue === '') {
        return '0'
      }

      if (numericValue === '0') {
        return '1'
      }

      if (parseInt(numericValue, 0) > 9) {
        return '9'
      }

      return numericValue
    },
    handleMultiplePacking(value) {
      return value.map(obj => `${obj.packing_material} : ${obj.layer}`).join('\n')
    },
    isProductIncomplete(product) {
      if (product.is_bundling === 1) {
        // Check if any is_registered is 1
        const allRegistered = product.bundle_component.every(item => item.is_registered === 1)

        // If there are registered items, return false
        if (allRegistered) {
          return false
        }

        // If all items are registered, check if any product_id is missing in selected items
        const productIdsInBundle = product.bundle_component.filter(item => item.is_registered === 0).map(item => item.product_id)
        const selectedIds = this.selected.map(item => item.product_id)
        const isProductIdsMissing = !productIdsInBundle.every(productId => selectedIds.includes(productId))

        if (isProductIdsMissing) {
          const indexToRemove = this.selected.findIndex(obj => obj.id === product.id)

          // Remove the object if found
          if (indexToRemove !== -1) {
            this.selected.splice(indexToRemove, 1)
          }
        }

        // Return false if product_id is missing
        return isProductIdsMissing
      }
      // Otherwise return true (not a bundling product)
      return true
    },
    truncateWithEllipsis(text) {
      return text.length <= 10 ? text : `${text.slice(0, 10)}...`
    },
  },
}
</script>

<style lang="scss" scoped>
@import './SubmissionHistory.scss';
</style>
